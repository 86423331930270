// ChatInput.js
import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import Paper from "@mui/material/Paper";
import LoadingAnimation from "./LoadingAnimation";
import Autocomplete from "@mui/material/Autocomplete";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Box } from "@mui/material";
import { models } from "../globals";

const commands = ["/apply", "/remember", "/help"];

export default function ChatInput({
  onSendMessage,
  isLoading,
  onModelChange,
  showModelSelect,
}) {
  const [inputValue, setInputValue] = useState("");
  const [selectedModel, setSelectedModel] = useState(models[0].name);

  const handleInputChange = (event, newValue) => {
    setInputValue(newValue);
  };

  const handleModelChange = (event) => {
    const selectedModel = event.target.value;
    console.log("Selected model:", event.target.value);
    setSelectedModel(selectedModel);
    onModelChange(selectedModel);
  };

  const handleSendClick = () => {
    if (inputValue && !isLoading) {
      const val = inputValue;
      setInputValue("");
      onSendMessage(val);
    }
  };

  return (
    <Paper
      component="form"
      sx={{
        p: "2px 4px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        boxShadow: "0px 0px 10px rgba(0, 128, 128, 0.5)",
        paddingBottom: "16px",
      }}
    >
      {showModelSelect && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            px: 3,
            pt: 1,
            justifyContent: "start",
          }}
        >
          <FormControl sx={{ minWidth: 120 }}>
            <Select
              labelId="model-select-label"
              id="model-select"
              value={selectedModel}
              onChange={handleModelChange}
              label="Model"
              variant="standard"
              sx={{
                color: "white",
                "& .MuiSelect-icon": { color: "white" },
                "&:before": { borderBottomColor: "transparent" },
                "&:after": { borderBottomColor: "transparent" },
                "&:hover:before": { borderBottomColor: "transparent" },
              }}
            >
              {models.map((model) => (
                <MenuItem key={model.name} value={model.name}>
                  {model.display_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}
      <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
        <Autocomplete
          freeSolo
          options={commands}
          getOptionLabel={(option) => option}
          inputValue={inputValue}
          onInputChange={handleInputChange}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              multiline
              maxRows={4}
              onKeyPress={(event) => {
                if (
                  event.key === "Enter" &&
                  !event.shiftKey &&
                  !isLoading &&
                  inputValue
                ) {
                  event.preventDefault();
                  const val = inputValue;
                  setInputValue("");
                  onSendMessage(val);
                }
              }}
              placeholder="Give me a task..."
              variant="outlined"
              sx={{
                ml: 1,
                flex: 1,
                "& .MuiOutlinedInput-notchedOutline": { border: "none" },
              }}
            />
          )}
          sx={{ width: "100%" }}
          autoHighlight
          selectOnFocus
          handleHomeEndKeys
          filterOptions={(options, state) => {
            const inputValue = state.inputValue.trim().toLowerCase();
            if (inputValue === "") {
              return [];
            }
            return options.filter((option) =>
              option.toLowerCase().startsWith(inputValue)
            );
          }}
        />
        <IconButton
          type="button"
          sx={{ p: "10px" }}
          aria-label="send"
          onClick={handleSendClick}
        >
          {isLoading ? <LoadingAnimation /> : <SendIcon />}
        </IconButton>
      </Box>
    </Paper>
  );
}
