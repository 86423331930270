import React from "react";
import { Typography, Paper, Box, styled } from "@mui/material";

export default function TaskHistory({ taskHistory }) {
  const getStageEmoji = (stage) => {
    switch(stage) {
      case 'PLAN_PENDING':
        return '🛠️';
      case 'PLAN':
        return '✉️';
      case 'CODE':
        return '⏳';
      case 'DONE':
        return '✅';
      default:
        return '';
    }
  };

  const TaskContainer = styled(Paper)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(3, 2),
    marginTop: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    fontWeight: theme.typography.fontWeightBold,
  }));

  const EmojiText = styled(Typography)(({ theme }) => ({
    marginLeft: theme.spacing(2),
    fontSize: "14pt",
  }));

  return (
    <>
      {taskHistory.length > 0 && <Typography variant="subtitle1">Threads from earlier</Typography>}
      {taskHistory.length === 0 && (
        <Typography variant="h6" textAlign="center" mt={3}>
          No threads yet
        </Typography>
      )}
      {taskHistory
        .sort((a, b) => {
          const dateA = new Date(a.created_at);
          const dateB = new Date(b.created_at);
          return dateB - dateA;
        })
        .filter(function (task) {
          return task.history[0]?.content;
        })
        .slice(0, 15)
        .map((task) => (
          <TaskContainer
            onClick={() => {
              window.location.href = `/chat/${task.task_id}`;
            }}
          >
            <span>
              {task.history[0]?.content.data
                ? task.history[0]?.content.data.length > 60
                  ? `${task.history[0]?.content.data.slice(0, 60)}...`
                  : task.history[0]?.content.data
                : `Empty task at ${task.created_at}`}
            </span>
            <EmojiText variant="body2">{getStageEmoji(task.stage)}</EmojiText>
          </TaskContainer>
        ))}
    </>
  );
}
