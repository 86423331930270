import React from 'react';
import { ThemeProvider, createTheme, CircularProgress, Box, CssBaseline } from '@mui/material';

const defaultTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#424242",
    },
  },
});

export default function Loading() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          '&::before': {
            content: '""',
            display: 'block',
            position: 'relative',
            width: '60px',
            height: '60px',
            borderRadius: '50%',
            background: `conic-gradient(${defaultTheme.palette.primary.main} 30deg, transparent 30deg 150deg, ${defaultTheme.palette.primary.main} 150deg)`,
            animation: 'pacman-animation 0.5s infinite',
          },
          '@keyframes pacman-animation': {
            '0%': { transform: 'rotate(0deg)' },
            '50%': { transform: 'rotate(-30deg)' },
            '100%': { transform: 'rotate(0deg)' },
          },
        }}
      >
        <CssBaseline />
        Fume is still working on this animation...
      </Box>
    </ThemeProvider>
  );
}
