// LoadingAnimation.js
import React from 'react';
import { Box, CircularProgress, Typography, colors } from '@mui/material';

export default function LoadingAnimation() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        mt: 0,
      }}
    >
      <CircularProgress size={40} thickness={4} sx={{ color: colors.teal[200], mb: 0, maxWidth: "25px", maxHeight: "25px"  }} />
    </Box>
  );
}