import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { green, purple } from "@mui/material/colors";
import { colors } from "@mui/material";
import { supabase } from "../supabaseClient";
import axios from "axios";
import { API_URL, ON_PREM } from "../globals";
import { CircularProgress } from "@mui/material";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://fumedev.com/">
        Fume Technologies, Inc.
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme({
  palette: {
    mode: "dark",
    primary: colors.teal,
  },
});

export default function SignUp() {
  const navigate = useNavigate();

  useEffect(() => {
    if (ON_PREM) {
      navigate('/signin'); // Redirect to signin page
    }
  }, [navigate]);

  // State for controlling modal visibility
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  async function generateCheckoutURL() {
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    try {
      const response = await axios.get(
        `${API_URL}/stripe/generate_checkout_url`,
        {
          headers: headers,
        }
      );
      console.log(response);
      return response.data.url;
    } catch (error) {
      console.log(error);
    }
  }

  async function signUpNewUser(
    email,
    password,
    firstName,
    lastName,
    company,
    position
  ) {
    setLoading(true);
    // Attempt to sign up the user.
    let { data, error: signUpError } = await supabase.auth.signUp({
      email: email,
      password: password,
    });

    // If there's an error in signing up, log it and alert the user.
    if (signUpError) {
      console.log(signUpError);
      alert("Oops, something went wrong.");
      return;
    }

    // Combine first name and last name into full name.
    let fullName = `${firstName} ${lastName}`;

    // Attempt to insert the user's details into the 'users' table.
    // Note: This operation uses the authenticated user's context automatically,
    // as the Supabase client library includes the user's access token in the request.
    let { data: insertData, error: insertError } = await supabase
      .from("users")
      .insert([
        {
          full_name: fullName,
          email: email,
          company: company,
          position: position,
        },
      ]);

    // If there's an error with the insert operation, log it and alert the user.
    if (insertError) {
      console.log(insertError);
      alert("Oops, something went wrong while saving your info.");
      return;
    }
    // Open the success modal
    if(!ON_PREM){
      let checkout_url = await generateCheckoutURL();
      // redirect to the checkout page
    window.location.href = checkout_url;
    }else{
      window.location.href = '/';
    }
    

  }

  // Function to handle "Get Started" button click
  const handleGetStartedClick = () => {
    navigate("/");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    signUpNewUser(
      data.get("email"),
      data.get("password"),
      data.get("firstName"),
      data.get("lastName"),
      data.get("company"),
      data.get("title")
    );
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: "50%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            justifySelf: "center",
          }}
        >
          <img
            src="logo.png"
            style={{ maxWidth: "150px", marginBottom: "20px" }}
          />
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="company"
                  required
                  fullWidth
                  id="company"
                  label="Company"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="title"
                  label="Title"
                  name="title"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
            </Grid>
            {loading ? (
              <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                <CircularProgress />
              </Box>
            ) : (
              <Button
                className="signup-button"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign Up
              </Button>
            )}
          </Box>
        </Box>
        <Typography variant="body2" align="center" sx={{ mt: 2 }}>
          Already have an account?{' '}
          <Link href="/signin" variant="body2">
            Sign in
          </Link>
        </Typography>
        <Copyright sx={{ mt: 5 }} />
        <div>
          <br />
          <p>
            "Programming: where we tell computers to do things, they do it, and
            we're still surprised it worked."
          </p>
          <footer>- ChatGPT</footer>
        </div>
      </Container>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="success-modal-title"
        aria-describedby="success-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="success-modal-title" variant="h6" component="h2">
            Registration Successful!
          </Typography>
          <Typography id="success-modal-description" sx={{ mt: 2 }}>
            Welcome aboard! Click below to get started.
          </Typography>
          <Button
            onClick={handleGetStartedClick}
            sx={{ mt: 3, mb: 2 }}
            variant="contained"
          >
            Get Started
          </Button>
        </Box>
      </Modal>
    </ThemeProvider>
  );
}
