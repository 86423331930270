import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Box,
  Button,
  Paper,
  Tab,
  Tabs,
  Typography,
  TextField,
  Alert,
  Snackbar,
  CircularProgress,
  Link,
} from "@mui/material";
import { supabase } from "../supabaseClient";
import axios from "axios";
import { API_URL, ON_PREM } from "../globals";
import { useNavigate } from "react-router-dom";
import AddRepoModal from "../components/AddRepoModal";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { DataGrid } from "@mui/x-data-grid";
import CheckIcon from "@mui/icons-material/Check";
import { green } from "@mui/material/colors";
import AddBoardMappingModal from "../components/AddBoardMappingModal";
import AddDevopsBoardMappingModal from "../components/AddDevopsBoardMappingModal";
import { Add } from "@mui/icons-material";

const defaultTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#424242",
    },
  },
});

const Settings = () => {
  const [boardMappings, setBoardMappings] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [devopsBoardMappings, setDevopsBoardMappings] = useState([]);
  const [availableRepos, setAvailableRepos] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [userEmail, setUserEmail] = useState("");
  const [newUserEmail, setNewUserEmail] = useState("");
  const [newUserFullName, setNewUserFullName] = useState("");
  const [newUserPassword, setNewUserPassword] = useState("");
  const [jiraUrl, setJiraUrl] = useState("");
  const [userMetadata, setUserMetadata] = useState({});
  const [isAddingAUser, setIsAddingAUser] = useState(false);
  const [teamData, setTeamData] = useState({});
  const [activeTab, setActiveTab] = useState("data");
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSlackIntegrated, setIsSlackIntegrated] = useState(false);

  const columns = [
    { field: "repo_name", headerName: "Repository Name", width: 200 },
    { field: "branch", headerName: "Branch", width: 160 },
    { field: "git_provider", headerName: "Git Provider", width: 130 },
    { field: "created_at", headerName: "Created At", width: 160 },
    { field: "documentation_state", headerName: "Status", width: 160 },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleRefreshRepo(params.row)}
          disabled={params.row.documentation_state === "PROGRESS" || isLoading}
        >
          {params.row.documentation_state === "PROGRESS"
            ? "In progress..."
            : "Refresh"}
        </Button>
      ),
    },
  ];

  const fetchBoardMappings = async () => {
    const {
      data: {
        session: { access_token },
      },
    } = await supabase.auth.getSession();
    const headers = { Authorization: `Bearer ${access_token}` };
    try {
      const response = await axios.get(`${API_URL}/get-board-mappings`, {
        headers,
      });
      setBoardMappings(response.data);
    } catch (error) {
      console.error("Failed to fetch board mappings:", error);
    }
  };


  const fetchTeams = async () => {
    const {
      data: {
        session: { access_token },
      },
    } = await supabase.auth.getSession();
    const headers = { Authorization: `Bearer ${access_token}` };
    try {
            const response = await axios.get(`${API_URL}/get-teams`, {
        headers,
      });
      console.log("response", response);
      setTeamMembers(response.data.teams[0].members);
      setTeamData(response.data.teams[0]);
    } catch (error) {
      console.error("Failed to fetch teams:", error);
       }
  };

  const fetchDevopsBoardMappings = async () => {
    const {
      data: {
        session: { access_token },
      },
    } = await supabase.auth.getSession();
    const headers = { Authorization: `Bearer ${access_token}` };
    try {
      const response = await axios.get(`${API_URL}/get-devops-mappings`, {
        headers,
      });
      setDevopsBoardMappings(response.data);
    } catch (error) {
      console.error("Failed to fetch board mappings:", error);

    }
  };

  useEffect(() => {
    fetchBoardMappings();
    fetchTeams();
    fetchDevopsBoardMappings(); 
  }, []);

  const jira_data_columns = [
    { field: "repo_name", headerName: "Repository Name", width: 200 },
    { field: "board_key", headerName: "Board Key", width: 200 },
  ];


  const team_data_columns = [
    { field: "id", headerName: "ID", width: 50 },
    { field: "email", headerName: "Email", width: 200 },
    { field: "full_name", headerName: "Full Name", width: 200 },
    { field: "role", headerName: "Role", width: 200 },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          onClick={() => handleRemoveUser(params.row)}
        >
          Remove
        </Button>
      ),
    },
  ];

  const handleRemoveUser = async (user) => {
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    try {
      const response = await axios.post(`${API_URL}/remove-member-from-team`, {
        member_id: user.id,
        team_id: teamData.id,
      }, {
        headers,
      });
      console.log(`User removed: ${user.email}`);
      await fetchTeams(); // Refresh the list of users
    } catch (error) {
      console.error(`Error removing user: ${user.email}`, error);
    }
  };

  const devops_data_columns = [
    { field: "repo_name", headerName: "Repository Name", width: 200 },
    { field: "tag", headerName: "Tag", width: 200 },
  ]

  const checkSlackIntegration = async () => {
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    try {
      const response = await axios.get(`${API_URL}/check-slack-integration`, {
        headers: headers,
      });
      setIsSlackIntegrated(response.data.slack_integration);
    } catch (error) {
      console.error("Failed to check Slack integration:", error);
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    getAvailableRepos();
    setOpenModal(false);
  };

  const handleTabChange = (event, newValue) => {
    console.log("newValue", newValue);
    setActiveTab(newValue);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleJiraSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    event.preventDefault();
    try {
      const response = await axios.post(
        `${API_URL}/update-jira-base-url`,
        {
          new_base_url: jiraUrl,
        },
        {
          headers: headers,
        }
      );
      setIsLoading(false);
      setJiraUrl(""); // Clear the input after successful update
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.response.data.error,
        severity: "error",
      });
    }
  };

  const getRowId = (row) => `${row.board_key}-${row.repo_name}`;
  const getUserRowId = (row) => row.email;

  const getJiraBaseUrl = async () => {
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    try {
      const response = await axios.get(`${API_URL}/get-jira-base-url`, {
        headers: headers,
      });
      setJiraUrl(response.data.jira_base_url);
    } catch (error) {
      console.error("Failed to fetch Jira base URL:", error);
    }
  };

  const getUserMetadata = async () => {
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    try {
      const response = await axios.get(`${API_URL}/get-user-metadata`, {
        headers: headers,
      });
      setUserMetadata(response.data);
    } catch (error) {
      console.error("Failed to fetch Jira base URL:", error);
    }
  };

  const handleRefreshRepo = async (repo) => {
    setIsLoading(true);
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    };

    const repoData = {
      git_provider: repo.git_provider,
      repo_name: repo.repo_name,
      branch: repo.branch,
      gitlab_token: repo.gitlab_access_token, // Include only if necessary and available
      gitlab_domain: repo.gitlab_domain, // Include only if necessary and available
    };

    try {
      const response = await axios.post(`${API_URL}/repo`, repoData, {
        headers,
      });
      console.log(`Repository re-added: ${repo.repo_name}`);
      await getAvailableRepos(); // Refresh the list of repositories
      setIsLoading(false);
    } catch (error) {
      console.error(`Error re-adding repository: ${repo.repo_name}`, error);
    }
  };

  async function getAvailableRepos() {
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    try {
      const response = await axios.get(`${API_URL}/repos`, {
        headers: headers,
      });

      if (response.data) {
        console.log(response);
        setAvailableRepos(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleNewUserSubmit = async () => {
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };    
    const userData = {
      email: newUserEmail,
      full_name: newUserFullName,
      password: newUserPassword,
      team_id: teamData.id
    };
    try {
      const response = await axios.post(`${API_URL}/add-member-to-team`, userData, {
        headers,
      });
      console.log(`User added: ${newUserEmail}`);
      await fetchTeams(); // Refresh the list of users
      setIsLoading(false);
      setIsAddingAUser(false)
    } catch (error) {
      console.error(`Error adding user: ${newUserEmail}`, error);
    }
  };

  useEffect(() => {
    getAvailableRepos();
    getJiraBaseUrl();
    checkSlackIntegration();
    getUserMetadata();
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
    const fetchUserDetails = async () => {
      const user = (await supabase.auth.getUser()).data.user;
      if (user && user.email) {
        console.log("Email:", user.email);
        setUserEmail(user.email);
      }
    };

    fetchUserDetails();
  }, []);

  const [boardMappingModalOpen, setBoardMappingModalOpen] = useState(false);
  const [devopsBoardMappingModalOpen, setDevopsBoardMappingModalOpen] = useState(false);

  const handleOpenBoardMappingModal = () => {
    setBoardMappingModalOpen(true);
  };

  const handleCloseBoardMappingModal = () => {
    setBoardMappingModalOpen(false);
  };

  useEffect(() => {
    // Redirects to the Stripe page when the "Subscriptions" tab is actively selected
    if (activeTab === "billing") {
      // '2' here assumes that the tabs are indexed from 0, 1, 2, where 2 represents the Subscriptions tab
      const emailParam = encodeURIComponent(userEmail);
      const stripeUrl = `https://pay.fumedev.com/p/login/8wM5nl8Ltcuyego144?prefilled_email=${emailParam}`;
      window.open(stripeUrl, "_blank"); // '_blank' used to open in a new tab
      setActiveTab("data"); // Reset the active tab to the default tab
    }
  }, [activeTab, userEmail]);

  const navigate = useNavigate();

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box
        sx={{
          display: "flex",
          height: "100vh",
          width: "md",
          paddingY: 20,
          paddingX: 40,
          margin: "auto",
          backgroundColor: "background.paper",
          color: "text.primary",
        }}
      >
        <Box
          sx={{
            width: "200px",
            backgroundColor: "background.paper",
            p: 2,
          }}
        >
          <Button
            backgroundColor="background.primary"
            onClick={() => navigate("/")}
          >
            Back
          </Button>
          <Tabs
            orientation="vertical"
            value={activeTab}
            onChange={handleTabChange}
            sx={{ borderRight: 1, borderColor: "divider" }}
          >
            <Tab label="Data" value="data" />
            <Tab label="Terms of Service" value="tos" />
            {!ON_PREM && <Tab label="Billing" value="billing" />}
            {userMetadata?.type === "root" && <Tab label="Integrations" value="integrations" />}
            {userMetadata?.type === "root" && <Tab label="Team" value="team" />}
          </Tabs>
        </Box>

        <Box sx={{ flex: 1, p: 3, maxWidth: "md" }}>
          {activeTab === "data" && (
            <Box>
              <Typography variant="h3" mb={3}>
                Data Sources
              </Typography>
              <Typography variant="h5" mb={2}>
                Connected Repositories
              </Typography>
              {availableRepos && (
                <Paper>
                  <Box sx={{ height: 400, width: "100%" }}>
                    <DataGrid
                      rows={[...availableRepos].sort((a, b) => {
                        let dateA = new Date(a.created_at);
                        let dateB = new Date(b.created_at);
                        return dateB - dateA;
                      })} // you might need to adjust the row format
                      columns={columns} // define this according to your repo's properties
                      pageSize={10}
                      rowsPerPageOptions={[10]}
                      pagination
                      // ...other props like onPageSizeChange and onPageChange
                    />
                  </Box>
                </Paper>
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenModal}
                sx={{ marginTop: 3 }}
              >
                Add Repository
              </Button>
            </Box>
          )}
          {activeTab === "tos" && (
            <Box
              sx={{ display: "flex", flexDirection: "column", height: "100%" }}
            >
              <Typography variant="h4" sx={{ marginBottom: 2 }}>
                Terms of Service
              </Typography>
              <Box sx={{ flex: 1, overflow: "auto" }}>
                <Document
                  file="/terms.pdf"
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  {Array.from(new Array(numPages), (el, index) => (
                    <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                  ))}
                </Document>
              </Box>
            </Box>
          )}
          {activeTab === "integrations" && ( // New Tab Content
            <Box>
              <Typography variant="h4">Integrations</Typography>
              <Box sx={{ p: 2, mt: 1, mb: 1, border: 1, borderColor: "divider" }}>
                <Typography variant="h6">
                  Slack
                </Typography>
                {isSlackIntegrated ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      color: green[500],
                    }}
                  >
                    <CheckIcon sx={{ marginRight: 1 }} />
                    <Typography>Slack Integration Active</Typography>
                  </Box>
                ) : (
                  <Typography>
                    You don't have your Slack integrations set up yet. Please
                    see doc.fumedev.com to learn how.
                  </Typography>
                )}
              </Box>
              <Box sx={{ p: 2, mt: 1, border: 1, borderColor: "divider" }}>
                <Typography variant="h6">Jira</Typography>
                <Typography
                  variant="h6"
                  sx={{ marginTop: 2, fontSize: "12pt" }}
                >
                  Board Mappings
                </Typography>
                <div
                  style={{
                    height: 200,
                    width: "100%",
                    paddingTop: 20,
                    paddingBottom: 10,
                  }}
                >
                  <DataGrid
                    rows={boardMappings}
                    columns={jira_data_columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    getRowId={getRowId}
                  />
                </div>
                <Box
                  component="form"
                  onSubmit={handleJiraSubmit}
                  sx={{ mt: 2, display: "flex", alignItems: "center" }}
                >
                  <TextField
                    label="Atlassian Base URL"
                    variant="outlined"
                    placeholder="company.atlassian.net"
                    value={jiraUrl}
                    onChange={(e) => setJiraUrl(e.target.value)}
                    sx={{ mr: 2, width: "100%" }}
                  />
                  {isLoading ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={{ mr: 2 }}
                    >
                      Submit
                    </Button>
                  )}
                </Box>
                {jiraUrl && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      color: green[500],
                      mt: 2,
                    }}
                  >
                    <CheckIcon sx={{ mr: 1 }} />
                    <Typography>
                      You are ready to install the Fume Jira app! You can learn
                      how to do so{" "}
                      <Link
                        href="https://developer.atlassian.com/console/install/c84282a9-dceb-4049-b632-0922befeb73f?signature=AYABeDt4NFvKw9Hg%2BiloZsTSxjIAAAADAAdhd3Mta21zAEthcm46YXdzOmttczp1cy1lYXN0LTE6NzA5NTg3ODM1MjQzOmtleS83ZjcxNzcxZC02OWM4LTRlOWItYWU5Ny05MzJkMmNhZjM0NDIAuAECAQB4KZa3ByJMxgsvFlMeMgRb2S0t8rnCLHGz2RGbmY8aB5YB5R2dxlLyM%2BKfkA46ffe%2BHgAAAH4wfAYJKoZIhvcNAQcGoG8wbQIBADBoBgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDKqUIMkNEgMPD%2Br1KAIBEIA7LtwKdJUDJJnX5RCqRCilFTec5z81AYLgvGmlcE%2Fk7R%2BVS3PO28kpbyoLAF4%2F9v3vHrQh%2FWxT27lyHpUAB2F3cy1rbXMAS2Fybjphd3M6a21zOmV1LXdlc3QtMTo3MDk1ODc4MzUyNDM6a2V5LzU1OWQ0NTE2LWE3OTEtNDdkZi1iYmVkLTAyNjFlODY4ZWE1YwC4AQICAHhHSGfAZiYvvl%2F9LQQFkXnRjF1ris3bi0pNob1s2MiregE261p4cKbIclgEyQCn%2BHiiAAAAfjB8BgkqhkiG9w0BBwagbzBtAgEAMGgGCSqGSIb3DQEHATAeBglghkgBZQMEAS4wEQQMqxZk7fvJCz8mjJuMAgEQgDu%2Fpck1q54Wl6o46Wg1VfYIMzONriIzrRg9rzYe11%2F%2Fi%2B6D9ERwZEXQfglre18SmfDIaemp5L1lnQc6BAAHYXdzLWttcwBLYXJuOmF3czprbXM6dXMtd2VzdC0yOjcwOTU4NzgzNTI0MzprZXkvM2M0YjQzMzctYTQzOS00ZmNhLWEwZDItNDcyYzE2ZWRhZmRjALgBAgIAePadDOCfSw%2BMRVmOIDQhHhGooaxQ%2FiwGaLB334n1X9RCAcxfR2blBBXh4UVXPNTT%2Bt4AAAB%2BMHwGCSqGSIb3DQEHBqBvMG0CAQAwaAYJKoZIhvcNAQcBMB4GCWCGSAFlAwQBLjARBAxu8vVmIrni0Kff%2FW4CARCAOwsWzI7TTEpiN8wB6QUD3ragOeumnSpUFdqEH72M1rBAehpDt3Kq%2FqN0d6GuydFze5nAuUcVUxIL3wWwAgAAAAAMAAAQAAAAAAAAAAAAAAAAAObHXo5e75bjMXAmi0txJ1z%2F%2F%2F%2F%2FAAAAAQAAAAAAAAAAAAAAAQAAADKJC8nBy3qGkw9ml%2BEvIioHhNStBFqzDBIfMK2MTUcsnOHiyRf7zKvrfnTKTugHJisVQBr6c0s%2BDre%2FFM1XH379kjE%3D&product=jira"
                        target="_blank"
                        color={green[300]}
                      >
                        here
                      </Link>
                      . If you have already installed the app, you can ignore
                      this message.
                    </Typography>
                  </Box>
                )}
                {jiraUrl && (
                  <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      onClick={handleOpenBoardMappingModal}
                    >
                      Add Board Mapping
                    </Button>
                  </Box>
                )}
              </Box>
              <Box sx={{ p: 2, mt: 1, mb: 1,  border: 1, borderColor: "divider" }}>
                <Typography variant="h6">Azure DevOps Boards</Typography>
                <Typography
                  variant="h6"
                  sx={{ marginTop: 2, fontSize: "12pt" }}
                >
                  Mappings
                </Typography>
                <div
                  style={{
                    height: 200,
                    width: "100%",
                    paddingTop: 20,
                    paddingBottom: 10,
                  }}
                >
                  <DataGrid
                    rows={devopsBoardMappings}
                    columns={devops_data_columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    getRowId={getRowId}
                  />
                </div>
                <Box sx={{ display: "flex", alignItems: "center", mt: 2}}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      onClick={() => setDevopsBoardMappingModalOpen(true)}
                    >
                      Add Mapping
                    </Button>
                  </Box>
              </Box>
            </Box>
          )}
          {activeTab === "team" && (
            <Box>
              <Typography variant="h4">Team</Typography>
              <Typography variant="h6" sx={{ marginTop: 2 }}>
                Manage Users
              </Typography>
              <div
                style={{
                  height: 400,
                  width: "100%",
                  paddingTop: 20,
                  paddingBottom: 10,
                }}
              >
                <DataGrid
                  rows={teamMembers}
                  columns={team_data_columns}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  getRowId={getUserRowId}
                />
              </div>
              <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={() => setIsAddingAUser(true)}
                >
                  Add Team Member
                </Button>
              </Box>
              {isAddingAUser && (
                //Horizontally placed textfiled and a button to add user by their email
                <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                  <TextField
                    label="Full Name"
                    variant="outlined"
                    placeholder="John Doe"
                    value={newUserFullName}
                    onChange={(e) => setNewUserFullName(e.target.value)}
                    sx={{ mr: 2, width: "100%" }}
                  />
                  <TextField
                    label="Email"
                    variant="outlined"
                    placeholder="user@example.com"
                    value={newUserEmail}
                    onChange={(e) => setNewUserEmail(e.target.value)}
                    sx={{ mr: 2, width: "100%" }}
                  />
                  <TextField
                    label="Password"
                    variant="outlined"
                    type="password"
                    value={newUserPassword}
                    onChange={(e) => setNewUserPassword(e.target.value)}
                    sx={{ mr: 2, width: "100%" }}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={handleNewUserSubmit}
                    sx={{ py: 2}}
                  >
                    Add
                  </Button>
                </Box>
              )}
            </Box>
          )}
        </Box>
        <AddRepoModal open={openModal} onClose={handleCloseModal} />
        <AddBoardMappingModal
          open={boardMappingModalOpen}
          onClose={handleCloseBoardMappingModal}
          repositories={availableRepos}
          baseUrl={jiraUrl}
        />
        <AddDevopsBoardMappingModal
          open={devopsBoardMappingModalOpen}
          onClose={() => setDevopsBoardMappingModalOpen(false)}
          repositories={availableRepos.filter((repo) => repo.git_provider === "AZURE_DEVOPS")}
        />
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackbar.severity}
            sx={{ width: "100%" }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </ThemeProvider>
  );
};

export default Settings;
