import { useState, useRef, useEffect, useContext } from 'react';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Paper, Typography, Button, Box, TextField, IconButton, Menu, MenuItem, colors } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CodeBlock from "./CodeBlock";
import ReactMarkdown from 'react-markdown';
import LoadingCard from './LoadingCard';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { PlanContext } from '../contexts/PlanContext';


const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});


const Cards = ({ papers, isLoading }) => {
  const [expandedPaper, setExpandedPaper] = useState(null);
  const [editedContent, setEditedContent] = useState([]);
  const [editMode, setEditMode] = useState(null);
  const editedContentRefs = useRef([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuIndex, setMenuIndex] = useState(null); // Add this state
  const open = Boolean(anchorEl);
  const { emitPlanUpdate } = useContext(PlanContext);

  useEffect(() => {
    setEditedContent(papers.map(paper => ({ ...paper })));
  }, [papers]);

  useEffect(() => {
    editedContentRefs.current = editedContentRefs.current.slice(0, editedContent.length);
  }, [editedContent]);

  const handleSeeMore = (index) => {
    setExpandedPaper(index === expandedPaper ? null : index);
  };

 const toggleEditMode = (index) => {
    if (editMode === index) {
      setEditMode(null);
      handleClose();
      emitPlanUpdate(editedContent);
    } else {
      setEditMode(index);
      setExpandedPaper(index);
      handleClose();
    }
  };

  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setMenuIndex(index); // Set the index here
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuIndex(null); // Reset the index here
  };

  const handleDelete = (index) => {
    const newContent = editedContent.filter((_, i) => i !== index);
    setEditedContent(newContent);
    handleClose();
    emitPlanUpdate(newContent); // Ensure the state reflects the updated list
  };

  const handleEditChange = (field, value, index) => {
    const newContent = [...editedContent];
    newContent[index] = { ...newContent[index], [field]: value };
    setEditedContent(newContent);
  };


  return (
    <ThemeProvider theme={darkTheme}>
      <Typography variant="h6" sx={{ marginBottom: "10px", marginTop: "32px" }}>
        Plan
      </Typography>
      <Box
        sx={{
          height: "100vh",
          overflowY: "auto",
          paddingRight: "16px",
          marginTop: "16px",
          paddingBottom: "100px",
        }}
      >
        {editedContent.map((plan, index) => (
          <Box key={index} sx={{ position: 'relative', marginBottom: "16px" }}>
            {!isLoading && (
              <Paper
                elevation={3}
                sx={{
                  padding: "16px",
                  paddingTop: "8px",
                  marginBottom: "16px",
                  maxHeight: expandedPaper === index ? "none" : "250px",
                  overflow: "hidden",
                  position: "relative",
                }}
                ref={(el) => (editedContentRefs.current[index] = el)}
              >
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={(event) => handleClick(event, index)} // Pass index here
                  sx={{ position: 'absolute', top: 8, right: 8 }}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="long-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      maxHeight: 48 * 4.5,
                      width: '20ch',
                    },
                  }}
                >
                  {editMode === menuIndex ? (
                    <MenuItem onClick={() => toggleEditMode(menuIndex)}>Save</MenuItem>
                  ) : (
                    <MenuItem onClick={() => toggleEditMode(menuIndex)}>Edit</MenuItem>
                  )}
                  <MenuItem onClick={() => handleDelete(menuIndex)}>Delete</MenuItem>
                </Menu>
                <Box
                  sx={{
                    display: "flex",
                    py: 1,
                    alignItems: "center",
                  }}
                >
                  {plan.completed && <CheckCircleIcon sx={{ color: "success.main", mr: 2 }} />}
                </Box>
                {editMode === index ? (
                  <>
                    <TextField
                      fullWidth
                      variant="outlined"
                      value={editedContent[index].file_path}
                      paddingTop="24px"
                      onChange={(e) => handleEditChange('file_path', e.target.value, index)}
                    />
                    <TextField
                      fullWidth
                      variant="outlined"
                      value={editedContent[index].title}
                      onChange={(e) => handleEditChange('title', e.target.value, index)}
                    />
                    <TextField
                      fullWidth
                      multiline
                      variant="outlined"
                      value={editedContent[index].content}
                      onChange={(e) => handleEditChange('content', e.target.value, index)}
                    />
                  </>
                ) : (
                  <>
                    <Typography
                      variant="body2"
                      component="div"
                      sx={{ fontWeight: "bold" }}
                    >
                      {plan.file_path}
                    </Typography>
                    <Typography variant="h6">{plan.title}</Typography>
                    <Typography variant="body1" component="div">
                      <ReactMarkdown components={{ code: CodeBlock }}>{plan.content}</ReactMarkdown>
                    </Typography>
                  </>
                )}
                {editedContentRefs.current[index] &&
                  editedContentRefs.current[index].scrollHeight > 250 &&
                  expandedPaper !== index && (
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        width: "100%",
                        backgroundImage:
                          "linear-gradient(to bottom, rgba(18, 18, 18, 0), rgba(18, 18, 18, 1))",
                        padding: "16px",
                        paddingTop: "72px",
                        textAlign: "center",
                      }}
                    >
                      <Button
                        onClick={() => handleSeeMore(index)}
                        sx={{ color: "white" }}
                      >
                        See More
                      </Button>
                    </Box>
                  )}
                {expandedPaper === index && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginTop: '8px',
                    }}
                  >
                    <Button
                      onClick={() => handleSeeMore(index)}
                      sx={{ color: "white" }}
                    >
                      See Less
                    </Button>
                    {editMode === index && (
                      <Button sx={{ color: "white" }} onClick={() => toggleEditMode(index)}>
                        Save
                      </Button>
                    )}
                  </Box>
                )}
              </Paper>
            )}
            {isLoading && (
              <LoadingCard />
            )}
          </Box>
        ))}
      </Box>
    </ThemeProvider>
  );
};

export default Cards;
