/* global HockeyStack */
// Chat.js
import React, { useEffect, useState, useRef } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import {
  colors,
  Typography,
  Button,
  IconButton,
  Modal,
  Box,
  CircularProgress
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL, ON_PREM } from "../globals";
import { supabase } from "../supabaseClient";
import AddRepoModal from "../components/AddRepoModal";
import Loading from "../pages/Loading";
import TaskHistory from "../components/TaskHistory";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Paper from "@mui/material/Paper";
import AppHeader from "../components/AppHeader";
import { useLastChat } from "../contexts/LastChatContext";
import OnboardingModal from "../components/OnboardingModal";
import SubscriptionCheckWrapper from "../components/SubscriptionCheckWrapper";

const defaultTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#424242",
    },
  },
});

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  padding: 2,
  borderRadius: 1,
};

export default function Home() {
  if (!ON_PREM){
    checkFreeTrial();
  }
  const navigate = useNavigate();
  
  const [user, setUser] = useState(null);
  const [taskHistory, setTaskHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { lastVisitedChat } = useLastChat();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const bottomRef = useRef(null);

  useEffect(() => {
    if (user && user?.email) {
      console.log("User", user);
      if (typeof HockeyStack !== 'undefined') HockeyStack.identify(user?.email);
    }
  }, [user]);

  async function checkFreeTrial() {
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    try {
      const response = await axios.get(`${API_URL}/check_free_trial`, {
        headers: headers,
      });
      console.log(response);

      const data = response.data;

      if (!data.used_free_trial) {
        const url = await generateStripeCheckoutUrl();
        if (url){
          window.location.href = url;
        }
      }

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async function generateStripeCheckoutUrl(){
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    try {
      const response = await axios.get(`${API_URL}/stripe/generate_checkout_url`, {
        headers: headers,
      });
      console.log(response);
      return response.data.url;
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {  
    getUser();
    getTaskHistory();
  }, []);

  async function startTask() {
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };



    try {
      const response = await axios.post(`${API_URL}/start_task`, null, {
        headers: headers,
      });
      console.log(response);
      navigate(`/chat/${response.data.task_id}`);
    } catch (error) {
      console.log(error);
    }
  }

  async function getUser() {
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    try {
      const response = await axios.get(`${API_URL}/user`, {
        headers: headers,
      });
      console.log(response);

      if (response.data) {
        setUser(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getTaskHistory() {
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
  
    try {
      const response = await axios.get(`${API_URL}/chats?page=${currentPage}&limit=20`, {
        headers: headers,
      });
      console.log(response);
  
      if (response.data) {
        console.log(response.data);
        setTaskHistory(response.data);
        setTotalPages(Math.ceil(response.data.total / 20));
        setIsLoading(false);
        setIsLoadingMore(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoadingMore(false);
    }
  }

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    navigate('/settings'); 
  };

  const [openHowToModal, setOpenHowToModal] = useState(false);
  const handleOpenHowToModal = () => {
    setOpenHowToModal(true);
  };
  const handleCloseHowToModal = () => {
    setOpenHowToModal(false);
  };

  const [showOnboardingModal, setShowOnboardingModal] = useState(false);

  useEffect(() => {
    const hasViewedOnboarding = localStorage.getItem("hasViewedOnboarding");
    console.log("hasViewedOnboarding", hasViewedOnboarding);
    if (!hasViewedOnboarding) {
      setShowOnboardingModal(true);
    }
  }, []);

  const handleOnboardingClose = () => {
    setShowOnboardingModal(false);
    localStorage.setItem("hasViewedOnboarding", "true");
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <SubscriptionCheckWrapper/>
      <OnboardingModal
        open={showOnboardingModal}
        onClose={handleOnboardingClose}
      />
      <AppHeader
        user={user}
        startTask={startTask}
        taskHistory={taskHistory}
        lastVisitedChat={lastVisitedChat}
      />
      <Container
        component="main"
        maxWidth="sm"
        sx={{ width: "100%", padding: 15 }}
      >
        <CssBaseline />
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <Typography variant="h1">
              Welcome, {user?.full_name.split(" ")[0]}
            </Typography>
            <Button
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                paddingY: 3,
                borderRadius: 2,
                backgroundColor: colors.teal[100],
                color: "black",
              }}
              onClick={startTask}
            >
              Start New
            </Button>
            <Button fullWidth sx={{ color: "white" }} onClick={handleOpenModal}>
              Add Repo
            </Button>
            <TaskHistory taskHistory={taskHistory} />
            {isLoadingMore && (
              <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                <CircularProgress />
              </Box>
            )}
            <div ref={bottomRef} />
            <AddRepoModal open={openModal} onClose={handleCloseModal} />
            <IconButton
              sx={{ position: "fixed", bottom: 16, left: 16 }}
              onClick={handleOpenHowToModal}
            >
              <HelpOutlineIcon />
            </IconButton>
            <Modal open={openHowToModal} onClose={handleCloseHowToModal}>
              <Paper sx={modalStyle}>
                <Typography variant="h6">How to Use</Typography>
                <Typography>
                  1. Add repository to your Fume absolute to get started.
                  <br />
                  2. Start a new task by clicking the "Start New" button.
                  <br />
                  3. You can give Fume a task and will start talking to you
                  about how to solve it.
                  <br />
                  4. You can keep discussing the best solution with Fume until
                  you are ready to start implementing it.
                  <br />
                  5. Once you are ready to start implementing the solution,
                  simply type '/apply'.
                  <br />
                  6. Fume will come back to you with a detailed plan of how to
                  implement the solution.
                  <br />
                  7. You can then accept or reject the plan and provide
                  feedback.
                  <br />
                  8. Fume will start writing code and submit a pull request for
                  you.
                </Typography>
              </Paper>
            </Modal>
          </>
        )}
      </Container>
    </ThemeProvider>
  );
}
