/* global HockeyStack */
// Chat.js
import React, { useState, useEffect, useRef, useContext } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import {
  colors,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Avatar,
  Menu,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Button,
  Paper,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AddIcon from "@mui/icons-material/Add";
import ChatInput from "../components/ChatInput";
import ChatMessages from "../components/ChatMessages";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL, models } from "../globals";
import { supabase } from "../supabaseClient";
import io from "socket.io-client";
import AppHeader from "../components/AppHeader";
import Loading from "./Loading";
import PlanDecision from "../components/PlanDecision";
import { useLastChat } from "../contexts/LastChatContext";
import WorkActivityIndicator from "../components/WorkActivityIndicator";
import { useAuth } from "../contexts/AuthContext";
import { useMediaQuery } from "@mui/material";
import Cards from "../components/Cards";
import { steps } from "framer-motion";
import { PlanContext } from "../contexts/PlanContext";
import SampleTasks from "../components/SampleTasks";
import { ListItemText } from "@mui/material";
import { Checkbox } from "@mui/material";
import Modal from "@mui/material/Modal";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const defaultTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#424242",
    },
  },
});

export default function Chat({}) {
  const { task_id } = useParams();
  const [socket, setSocket] = useState(null);
  const [authToken, setAuthToken] = useState(null);
  const [liveResponse, setLiveResponse] = useState(null);
  const [cards, setCards] = useState([]);
  const [isLoadingCards, setIsLoadingCards] = useState(false);
  const { onPlanUpdate } = useContext(PlanContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const { session } = useAuth();

  useEffect(() => {
    if (!task_id) {
      navigate('/notfound')
    }
  }, [])

  useEffect(() => {
    if (session) {
      setAuthToken(session.access_token);
    }
  }, [session]);

  useEffect(() => {
    if (authToken && task_id) {
      console.log("Connecting to socket", task_id);
      const socket_temp = io(
        `${API_URL.replace("http://", "ws://").replace(
          "https://",
          "wss://"
        )}/chat`,
        {
          query: { task_id: task_id },
          extraHeaders: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      setSocket(socket_temp);

      getUser();
      getTask();
      getAvailableRepos();
    }
  }, [authToken, task_id]);

  useEffect(() => {
    if (socket) {
      console.log("Socket connected", task_id);
      // Listen for incoming messages
      socket.on("message", (message) => {
        console.log("New Message: ", message);
        if (message.data.type === "chunk") {
          setLiveResponse((prevLiveResponse) => {
            if (prevLiveResponse) {
              return {
                ...prevLiveResponse,
                content: {
                  ...prevLiveResponse.content,
                  data: prevLiveResponse.content.data + message.data.data,
                },
              };
            } else {
              return {
                id: Date.now(),
                content: {
                  type: "liveResponse",
                  data: message.data.data,
                },
              };
            }
          });
        } else if (message.data.type === "plan_card") {
          // Handle 'plan_card' type message to update cards
          console.log("Plan Card: ", message.data.data);
          const steps = message.data.data.steps;
          setIsLoadingCards(false);
          setCards(steps);
        } else if (message.data.type === "plan_card_loading") {
          // Handle 'plan_card_loading' type message to toggle card loading state
          setIsLoadingCards(true);
        } else {
          setLiveResponse(null);
          const message_obj = {
            role: "assistant",
            content: message.data,
          };
          setMessages((prevMessages) => [...prevMessages, message_obj]);
          if (!message.data.wait) {
            setIsLoading(false);
          }
        }
      });

      // Clean up on component unmount or when task_id changes
      return () => {
        socket.off("message");
        socket.disconnect();
      };
    } else {
      console.log("Socket disconnected");
    }
  }, [socket, task_id]);

  useEffect(() => {
    if (socket) {
      // Function to handle plan updates
      const handlePlanUpdate = (updatedPlan) => {
        console.log("Plan updated, emitting changes:", updatedPlan);
        // Emitting updated plan to the backend or through WebSocket
        socket.emit(
          "message",
          JSON.stringify({
            task_id: task_id,
            action: "update_plan",
            plan: { steps: updatedPlan },
          })
        );
      };

      // Registering the listener for plan updates
      const unsubscribe = onPlanUpdate(handlePlanUpdate);

      // Cleanup function to unsubscribe when the component unmounts
      return () => {
        unsubscribe();
      };
    }
  }, [socket, onPlanUpdate]);

  const [user, setUser] = useState({});
  const [task, setTask] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState();
  const [selectedRepo, setSelectedRepo] = useState(-1);
  const [availableRepos, setAvailableRepos] = useState([]);
  const [selectedModel, setSelectedModel] = useState(models[0].name);
  const theme = useTheme();

  const [isReady, setIsReady] = useState(false);
  const { setLastVisitedChat } = useLastChat(); // Use the hook to get the setLastVisitedChat function

  // Sample tasks array could potentially be fetched or derived from state
  console.log("task", task);
  const tasks = task.selected_repo
    ? [
        ...(task.context_repos.length > 0
          ? task.context_repos.map((repo) => ({
              label: `${repo.split("-")[0]}/${repo.split("-")[1]}`,
            }))
          : []),
        { label: `${task.selected_repo.repo_name}` },
        { label: "+ Add Repo" },
      ]
    : [{ label: "+ Add Repo" }];

  const handleTaskClick = (taskLabel) => {
    if (taskLabel === "+ Add Repo") {
      setIsModalOpen(true);
    } else {
    }
  };

  useEffect(() => {
    return () => {
      getTask();
      if (task_id) {
        setLastVisitedChat(task_id); // Update the last visited chat on navigate away
      }
    };
  }, [task_id, setLastVisitedChat]);

  const bottomRef = useRef(null);

  const handleModelChange = async (newModel) => {
    setSelectedModel(newModel);

    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    try {
      const response = await axios.put(
        `${API_URL}/select_model`,
        { task_id: task_id, model_name: newModel },
        {
          headers: headers,
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  function resetStates() {
    setCards([]);
    setMessage("");
    setMessages([]);
    setSelectedRepo(-1);
    setTask({});
    setAuthToken(null);
    setIsLoading(false);
    setLiveResponse(null);
    setIsReady(false); // Add this line
    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }

  async function getUser() {
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    try {
      const response = await axios.get(`${API_URL}/user`, {
        headers: headers,
      });

      if (response.data) {
        setUser(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getAvailableRepos() {
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    try {
      const response = await axios.get(`${API_URL}/repos`, {
        headers: headers,
      });

      if (response.data) {
        console.log("response.data", response.data);
        setAvailableRepos(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function startTask() {
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    try {
      const response = await axios.post(`${API_URL}/start_task`, null, {
        headers: headers,
      });
      resetStates(); // Add await here
      if (socket) {
        socket.disconnect();
      }
      navigate(`/chat/${response.data.task_id}`);
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  }

  async function getTask() {
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    try {
      const response = await axios.get(`${API_URL}/chat/${task_id}`, {
        headers: headers,
      });

      if (response.data) {
        setTask(response.data);
        console.log("Task", response.data);
        if (!messages) {
          setMessages(response.data.history);
          console.log("Existing plan:", response.data.plan.steps);
          setCards(response.data.plan.steps);
        }
        setIsReady(true);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function approvePlan() {
    try {
      socket.emit(
        "message",
        JSON.stringify({
          task_id: task_id,
          action: "approve_plan",
          model_name: selectedModel,
        })
      );
    } catch (error) {
      console.log(error);
    }
  }

  async function rejectPlan() {
    try {
      socket.emit(
        "message",
        JSON.stringify({
          task_id: task_id,
          action: "reject_plan",
          model_name: selectedModel,
        })
      );
    } catch (error) {
      console.log(error);
    }
  }

  const sendMessage = (message) => {
    setMessage(message);
    if (typeof HockeyStack !== "undefined")
      HockeyStack.goal("Send Message", { message: message });
    console.log("Sending message:", message);
    if (message.trim() && socket && socket.connected) {
      // Add socket.connected check
      setIsLoading(true);
      const message_obj = {
        task_id: task_id,
        message: message,
        model_name: selectedModel,
      };
      const msg = {
        role: "user",
        content: { data: message, type: "chat_message" },
      };
      setMessages((prevMessages) => [...prevMessages, msg]);
      socket.emit("message", JSON.stringify(message_obj));
      setMessage("");
    } else {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          role: "error",
          content: {
            data: "An error occurred while sending the message. Please try again later.",
            type: "error",
          },
        },
      ]);
    }
  };

  const createPRAction = () => {
    let message = "/apply";
    console.log("Sending message:", message);
    if (message.trim()) {
      setIsLoading(true);
      const message_obj = {
        task_id: task_id,
        message: message,
        model_name: selectedModel,
      };
      const msg = {
        role: "user",
        content: { data: message, type: "chat_message" },
      };
      setMessages((prevMessages) => [...prevMessages, msg]);
      socket.emit("message", JSON.stringify(message_obj));
      setMessage("");
    }
  };

  const hasNoMessages = !messages || messages?.length === 0;

  const handleOptionChange = (event) => {
    setSelectedRepo(event.target.value);
  };

  async function confirmSelectedRepo() {
    if (typeof HockeyStack !== "undefined")
      HockeyStack.goal("Task Start", { selected_repo: selectedRepo });
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    try {
      const response = await axios.put(
        `${API_URL}/select_repo`,
        {
          task_id: task_id,
          repo_id: selectedRepo,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            // Add other headers here if needed
          },
        }
      );
      console.log(response);
      getTask();
    } catch (error) {
      console.log(error);
    }
  }

  const [planProposed, setPlanProposed] = useState(false);
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    getTask();

    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }

    if (messages?.length > 0) {
      const lastMessage = messages[messages.length - 1];
      if (lastMessage.content.type === "plan") {
        if (typeof HockeyStack !== "undefined")
          HockeyStack.goal("Plan Proposed", { plan: lastMessage.content.data });
        setPlanProposed(true);
      } else {
        setPlanProposed(false);
      }
    }
  }, [messages]);

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const [selectedRepos, setSelectedRepos] = useState([]);

  const addContextRepo = async () => {
    let count = 0;
    if (task.context_repos) {
      count = task.context_repos.length;
    }
    if (selectedRepos.length + count > 1) {
      setSnackbarMessage("You can only add up to 3 context repositories.");
      setSnackbarOpen(true);
      return;
    }

    for (const repoName of selectedRepos) {
      const repoDetails = availableRepos.find(
        (repo) => repo?.repo_name === repoName
      );
      if (repoDetails) {
        const formattedName = `${repoDetails.repo_name.replace("/", "-")}-${
          repoDetails.branch
        }`;
        const url = API_URL + "/add-context-repo";
        console.log(`Making request to: ${url} with token: ${authToken}`);
        try {
          const response = await axios.post(
            url,
            {
              task_id: task.task_id,
              repo_name: formattedName,
            },
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            }
          );

          if (response.data.error) {
            setSnackbarMessage(response.data.error);
            setSnackbarOpen(true);
          } else {
            getTask();
          }
        } catch (error) {
          console.error("Failed to add repository to context:", error);
          setSnackbarMessage("Failed to add repository to context.");
          setSnackbarOpen(true);
        }
      }
    }

    handleModalClose(); // Close the modal after processing all repositories
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleRepoChange = (event) => {
    setSelectedRepos(event.target.value);
  };

  let contextRepoNames = [];
  if (task.context_repos?.length > 0) {
    contextRepoNames = task?.context_repos.map(
      (repo) => `${repo.split("-")[0]}/${repo.split("-")[1]}`
    );
  }

  console.log("contextRepoNames", contextRepoNames);

  const modalBody = (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
      }}
    >
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Select Repositories
      </Typography>
      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel id="demo-multiple-checkbox-label">Repos</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={selectedRepos}
          onChange={handleRepoChange}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 224,
                width: 250,
              },
            },
          }}
        >
          {availableRepos
            .filter(
              (repo) =>
                repo.documentation_state === "SUCCESS" &&
                repo.repo_name !== task.selected_repo?.repo_name &&
                !contextRepoNames.includes(repo.repo_name)
            )
            .map((repo) => (
              <MenuItem key={repo.id} value={repo.repo_name}>
                <Checkbox
                  color="primary"
                  checked={selectedRepos.indexOf(repo.repo_name) > -1}
                />
                <ListItemText primary={repo.repo_name} />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
        <Button
          onClick={addContextRepo}
          variant="contained"
          color="primary"
          sx={{ mr: 1 }}
        >
          Submit
        </Button>
        <Button onClick={handleModalClose} variant="outlined" color="primary">
          Close
        </Button>
      </Box>
    </Box>
  );

  let showCards = task?.selected_repo?.id && isLargeScreen && cards?.length > 0;

  return (
    <ThemeProvider theme={defaultTheme}>
      <AppHeader
        user={user ? user : null}
        startTask={startTask}
        pos={showCards ? "left" : "top"}
      />
      <Container
        component="main"
        maxWidth={false}
        sx={{
          display: "flex",
          height: "100%",
          width: showCards ? "calc(100vw - 250px)" : "100vw",
          overflow: "hidden",
          marginLeft: showCards ? "240px" : "0px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100vh",
            width: showCards ? "65%" : isLargeScreen ? "60%" : "100%",
            margin: showCards ? 0 : "auto",
          }}
        >
          <CssBaseline />
          {!isReady && <Loading />}
          {isReady && hasNoMessages && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexGrow: 1,
                flexDirection: "column",
              }}
            >
              {task?.selected_repo?.id ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "calc(100vh + 30px)",
                    p: 3,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      mt: 5,
                    }}
                  >
                    <Typography variant="h6" gutterBottom>
                      Let's accelerate {task.selected_repo.repo_name}!
                    </Typography>
                    <Paper
                      elevation={3}
                      sx={{ mt: 2, p: 2, width: "100%", textAlign: "center" }}
                    >
                      <Typography variant="body1">
                        Ask me anything about the codebase! It can either be
                        task or a question.
                        <br />
                        <br />
                        You can copy and paste error logs and I will analyze the
                        codebase to suggest a possible solution.
                        <br />
                        <br />
                        Whenver you are ready to implement the changes,{" "}
                        <strong>type '/apply'</strong> and I will submit a pull
                        request for it. Or you can copy and paste the changes
                        too if you don't want to wait for me to write code (I'm
                        a little slow at the moment)
                      </Typography>
                    </Paper>
                  </Box>
                  <Container>
                    <SampleTasks
                      tasks={tasks.map((task) => task.label)}
                      handleTaskClick={handleTaskClick}
                    />
                    <ChatInput
                      onSendMessage={sendMessage}
                      isLoading={isLoading}
                      onModelChange={handleModelChange}
                      showModelSelect={false}
                    />
                  </Container>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100vh",
                    width: "100vw",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    variant="h5"
                    component="h2"
                    color="textSecondary"
                    textAlign="center"
                    padding="20px"
                  >
                    Please select a repository to start a task
                  </Typography>
                  <FormControl variant="outlined" sx={{ minWidth: 120, mr: 2 }}>
                    <InputLabel>Select a repository</InputLabel>
                    <Select
                      value={selectedRepo}
                      onChange={handleOptionChange}
                      label="Select a repository"
                      sx={{ width: "300px" }}
                    >
                      {availableRepos
                        .filter(
                          (repo) => repo.documentation_state === "SUCCESS"
                        )
                        .map((repo) => (
                          <MenuItem key={repo.id} value={repo.id}>
                            {repo.repo_name} ({repo.branch})
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {selectedRepo && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={confirmSelectedRepo}
                      sx={{ mt: 3, mb: 2, paddingY: 2 }}
                    >
                      Start Task
                    </Button>
                  )}
                </Box>
              )}
            </Box>
          )}
          {!hasNoMessages && (
            <Box
              sx={{ display: "flex", flexDirection: "column", height: "100%" }}
            >
              <Box sx={{ flexGrow: 1, overflowY: "auto", mb: 0 }}>
                <ChatMessages
                  messages={messages}
                  createPRAction={createPRAction}
                  liveResponse={liveResponse}
                  sendMessage={sendMessage}
                />
                <div ref={bottomRef} />
              </Box>
              {task?.selected_repo?.id && (
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  {planProposed ? (
                    <PlanDecision
                      onYes={approvePlan}
                      onNo={rejectPlan}
                      isLoading={isLoading}
                    />
                  ) : task.stage === "PLAN_PENDING" || task.stage === "CODE" || (typeof messages[messages.length - 1]?.content?.data === "string" && messages[messages.length - 1].content.data.startsWith("/help"))  ? (
                    <WorkActivityIndicator />
                  ) : (
                    <Container>
                      <SampleTasks
                        tasks={tasks.map((task) => task.label)}
                        handleTaskClick={handleTaskClick}
                      />
                      <ChatInput
                        onSendMessage={sendMessage}
                        isLoading={isLoading}
                        onModelChange={handleModelChange}
                        showModelSelect={false}
                      />
                    </Container>
                  )}
                </Box>
              )}
            </Box>
          )}
        </Box>
        {showCards && (
          <Box
            sx={{
              width: "30%",
              height: "100vh",
              position: "fixed",
              right: 0,
              top: 0,
              marginLeft: "10px",
            }}
          >
            <Cards papers={cards} isLoading={isLoadingCards} />
          </Box>
        )}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            autoHideDuration={10000}
            onClose={handleCloseSnackbar}
            severity="error"
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <Modal
          open={isModalOpen}
          onClose={handleModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          {modalBody}
        </Modal>
      </Container>
    </ThemeProvider>
  );
}
